@import '../../assets/styles/variables.scss';

.header {
    width: 100%;
    display: flex;
    align-items: center;
}

.header-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .circle {
        display: flex;
        margin: 1rem 1rem 0.2rem;
        width: 3rem;
        height: 3rem;
        border-radius: 5rem;
        background: $circle-inactive-color;
        position: relative;

        @media screen and (max-width: $breakpoint-md) {
            margin:0.5rem;
        }

        p {
            margin: auto;
            color: #ffff;
            font-size: 1.2rem;
        }

        &.active{
            background: $primary-color;
        }
    }

    .circle-label {
        font-size: 12px;
    }
}

