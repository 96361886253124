@import 'src/assets/styles/variables';

#question-form {
  padding: 1.5rem;
}

.removeAnswerButton {
  cursor: pointer;
  color: crimson;
  position: relative;
}