@import '../../../assets/styles/variables';

.candidates-home {
  padding: 50px;
  height: 100%;

  @media screen and (min-width: 2000px) {
    width: 2000px;
    margin: auto;
  }
}

.candidate-list-col {
  .candidate-list {
    margin-top: 45px;

    .candidate-row {
      display: flex;
    }
  }
}

.pagination-bottom {
  display: flex;
  justify-content:center;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;

  .pagination {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(40px);
  }

  .pagination-count {
    font-weight: 500;
  }
}

.background-bo {
  background-color: #F9FAFF;
  width: 100%;
  height: 100%;
}

.spinner-height {
  height: 500px;
}

.candidates-header {
  text-align: left;
  font-size: 22px;
  margin-bottom: 30px;
}