@import '../../assets/styles/variables.scss';


.container-login {
  display: flex;
  color: white;
  height: 100vh;
  text-align: left;

  h1 {
    font-size: 50px;
    font-weight: 400;
    font-family: 'Baumans', sans-serif;
    margin: 0;
  }

  h3 {
    font-size: 27px;
    font-weight: 500;
    margin: 0 0 1.5rem;
    font-family: 'Montserrat', sans-serif;
  }

  p {
    width: 50%;
  }

  .left-content,
  .button-connexion {
    position: absolute;
    top: 40%;
  }

  .button-connexion {
    a {
      border-radius: 35px;
      font-size: 16px;
    }

    img {
      width: 28px;
      margin-right: 15px;
    }
  }

  .left-container {
    background-position: right;
    background-size: auto 90%;
    background-repeat: no-repeat;
    background-color: $secondary-blue;
    width: 60%;
    position: relative;
    padding-left: 120px;

    img {
      background-repeat: no-repeat;
      width: 80px;
    }

    .brand {
      margin-top: 180px;
    }
  }

  .right-container {
    background-position: left;
    background-repeat: no-repeat;
    background-size: auto 90%;
    background-color: $main-blue;
    width: 40%;
    position: relative;
    padding-left: 80px;
  }
}

.navbar-menu {
  background: $main-blue;
}