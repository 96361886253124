@import 'src/assets/styles/variables';

.number-input {

  input[type=number] {
    text-align: center;
    border: 1px solid $light-gray-action-color;
    margin-left: 5px;
    margin-right: 5px;
    outline: none;
    padding: 5px;
    width: 3rem;
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  .spinner-btn {
    background-color: $main-blue;
    outline: none;
    height: 2rem;
    width: 2rem;
    border: none;
    border-radius: 50%;
    color: white;
    &:disabled{
      opacity: .65;
    }
  }
}
