:root {
  --main-color: #1653f0;
  --secondary-color: #0700e0;
  --tertiary-color: #7b01d4;
}

$main-blue: var(--main-color);
$secondary-blue: var(--secondary-color);
$tertiary-color: var(--tertiary-color);

$background-url: 'assets/background_steaminterview_light-grey.png';
$background-url-check: 'assets/accept.png';
$background-white: #f9f9f9;
$dark-grey-action-color: #343a40;
$light-gray-action-color: #e6e6e6;
$action-color: #1653f0;
$tertiary-color: #7b01d4;
$primary-color: #ff7043;

$grey-color: #e0e0e0;
$box-shadow-color: #bababa;
$circle-inactive-color: #bdbdbd;

$breakpoint-md: 992px;
$breakpoint-sm: 767px;




