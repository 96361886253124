@import '../../../../../assets/styles/variables';

.no-document {
  margin: auto;
}

.document {
  cursor: pointer;
  background: white;
  box-shadow: 0 1px 5px 0 #bababa;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;

  .file {
    font-size: 10px;
    padding-right: 1rem;

    .icon-pdf {
      color: red;
    }

    .icon-image {
      color: grey;
    }

    svg {
      margin: auto;
    }
  }

  .file:hover {
    background-color: $light-gray-action-color;
  }
}