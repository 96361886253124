@import 'src/assets/styles/variables';

.section-content {
  margin: 30px 0;

  .section-header {
    font-size: 2.5rem;
    padding-bottom: 30px;
  }

  .card-header:hover{
    background-color: $light-gray-action-color;
  }

  .jodit-container .jodit .jodit_theme_default .jodit-wysiwyg_mode{
    height: auto;
  }
}

.section-form {
  text-align: left;
  max-width: 50%;
}

.accordeon-section {
  background: white;
  text-align: left;
}

.accordeon-section-with-border {
  border: 1px solid rgba(0,0,0,.125) !important;
}