@import 'src/assets/styles/variables';

.logic-test {
  .info-quiz {
    margin-bottom: 1rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    text-align: left;
    font: {
      size: 1.2rem;
    }

    span {
      color: $primary-color
    }
  }

  .paper-card {
    .header {
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  .container-choice {
    display: block;
    position: relative;
    padding-left: 40px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* Hide the browser's default radio button */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    label {
      margin-top: 6px;
    }

    /*Custom radio button*/
    .check-mark {
      position: absolute;
      top: 5px;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: $grey-color;

      &:hover {
        background-color: #bdbdbd;
      }

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }

    /* When the radio button is checked, add a background */
    input:checked ~ .check-mark {
      background-color: $main-blue;
    }

    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .check-mark:after {
      display: block;
    }
  }

  .container-choice.type-radio {
    .check-mark {
      border-radius: 50%;
    }
  }

  .container-choice.type-checkbox {
    .check-mark {
      border-radius: 10%;
    }
  }


  .progressBar {
    background-color: #e0e0e0;
    height: 1.5rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 1rem;

    .progress-bar {
      background: $main-blue;
    }
  }
}


