@import '../../../../assets/styles/variables';

.search {
  .form-group {
    text-align: left;
    margin-bottom: 0;

    .form-label {
      text-align: left;
      font-size: 0.8rem;
      color: $main-blue;
    }

    &.search-bar {
      min-width: 280px;
      margin-right: 20px;
    }

    &.search-select {
      min-width: 180px;
    }
  }
}
