@import '../../../assets/styles/variables';

.candidate-form {
  .form-header {
    text-align: left;
    font-weight: bold;
    color: $main-blue;
    border-bottom: 1px solid $main-blue;
    padding-bottom: 5px;
    margin-bottom: 20px;
  }

  .section-lits-item {
    cursor: pointer;
  }

  .section-label {
    font-size: 1.2rem;
  }
}
