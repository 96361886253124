@import '../../../../../assets/styles/variables';

.comment-data {
  color: $secondary-blue;
  text-align: left;

  .author {
    font-weight: bold;
  }

  .edit-icon {
    cursor: pointer;
  }
}
