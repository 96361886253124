@import '../../assets/styles/variables.scss';

.perception {
    .info-quiz {
        margin-bottom: 1rem;
        margin-right: 0.25rem;
        margin-left: 0.25rem;
        text-align: left;
        font-size: 1.2rem;

        span {
            color: $primary-color
        }
    }

    .paper-card {
        .header {
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 2rem;
        }
    }
}

