@import '../../assets/styles/variables';

button {
  text-decoration: none;
  color: black;
  border: none;
  background-color: transparent;
  &:hover {
    color: $primary-color;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
  }
}