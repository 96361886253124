@import '../../assets/styles/variables.scss';

.paper-card {
  background-color: #ffff;
  padding: 1.5rem;

  .form-group {
    .form-label {
      font-size: 1.5rem;
    }
  }

}




