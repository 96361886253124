@import 'src/assets/styles/variables';


.test-section {
  background: white;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 1px 1px 2px 0 $box-shadow-color;

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .section-title {
    color: black;
  }
}