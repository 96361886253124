@import 'assets/styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Baumans&family=Montserrat');

$primary-font: 'Montserrat', sans-serif;
$secondary-font: 'Baumans', sans-serif;

body {
  height: 100%;
  width: 100%;

  background-color: $background-white;
  color: black;

  .app {
    text-align: center;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $breakpoint-md) {
      h1 {
        font-size: 30px;
      }
    }
  }
}

html, #root, .app {
  height: 100%;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 27px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 1rem;
}

h3 {
  font-size: 22px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 1rem;
}

.accentuated-title {
  font-weight: 400;
  font-family: $primary-font;
  background: linear-gradient(90deg, $main-blue 20%, $tertiary-color 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.margin-auto {
  margin: auto;
}

.btn-primary {
  background-color: $secondary-blue;
  border-color: $secondary-blue;

  &:hover {
    background-color: transparent;
    color: $secondary-blue;
  }

  &:active, &:visited, &:disabled {
    background-color: $secondary-blue;
    border-color: $secondary-blue;
    opacity: 0.5;
  }

  &:focus {
    background-color: $secondary-blue;
    border-color: $secondary-blue;
    color: white;

    &:hover {
      color: white;
    }
  }

  &:disabled {
    &:hover {
      color: white;
    }
  }
}

.btn-outline-primary {
  border-color: $secondary-blue;
  color: $secondary-blue;

  &:hover {
    background-color: $secondary-blue;
    border-color: $secondary-blue;
  }
}

.form-control {
  color: black !important;
}

.icon-success {
  color: forestgreen;
}
