@import '../../../../assets/styles/variables';

.polaroid {
  background: white;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0 $light-gray-action-color;
  height: auto;
  flex: 1;
  margin: 12px;
  cursor: pointer;
  text-decoration: none !important;

  :hover {
    text-decoration: none !important;
  }

  .user-infos {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .name {
      color: black;
      font-weight: 500;
      text-decoration: none;
      text-align: left;
    }

    .test-date {
      font-size: 0.70em;
      color: gray;
    }
  }

  &.empty {
    background: none;
    box-shadow: none;
  }
}

.result-icon {
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  margin-left: 10px;
  background-size: contain;

  &.check-icon {
    background-image: url('../../../../assets/accept.png');
  }
  &.waiting-icon {
    background-image: url('../../../../assets/accept.png');
  }
}

.polaroid:hover {
  background-color: $light-gray-action-color;
}
