@import '../../../../assets/styles/variables';


.candidate-profile {
  display: flex;
  align-items: center;

  .candidate-infos {
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }

  .avatar {
    margin-left: 0;

    .photo {
      height: 150px;
      width: 150px;
    }
  }

  .name {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: left;
  }

  .pourcentage-score {
    margin-bottom: 0.8rem;
    text-align: left;

    span {
      box-shadow: 0 4px 4px 0 rgba(62, 88, 199, 0.09);
      color: #F39200;
      font-weight: bold;
      font-size: 1.2rem;
      padding: 0.5rem 1.2rem;
      background: white;
      border-radius: 100px;
    }
  }
}

.profile-table {
  th {
    font-weight: normal;
    border: none;
  }

  .small-col {
    font-weight: bold;
    width: 20%;
  }

  .medium-col {
    font-weight: bold;
    width: 25%;
  }
}

.auto-perception-table {
  border: 1px solid $light-gray-action-color;
  thead {
    background: white;
  }
}

.border-separator {
  border-right: 1px solid #0000004A;
}
