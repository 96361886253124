@import 'src/assets/styles/variables';

.modal-body {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  .stop-watch-icon {
    color: $primary-color
  }
  .modal-custom-title {
    font-size: 2rem;
    margin: 1rem;
  }
  .modal-text {
    font-size: 1.25rem;
  }
}