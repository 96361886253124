@import '../../../assets/styles/variables';

.nav-panel{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;

  .nav-item {
    text-align: center;
    background-color: white;
    text-decoration: none;
    color: black;
    border: white;
    border-radius: 7px;
    width: 65px;
    padding: 5px;
    margin: 4px;
    &.active{
      background-color: $main-blue;
      color: white;
    }
    &:focus{
      outline: none;
      text-decoration: none;
    }
  }
}
